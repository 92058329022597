import makeModelYearService from "@/services/makeModelYear.service";

const state = {
  yearList: [],
  makeList: [],
  modelList: [],
  makeModelList: [],
};

const actions = {
  getYears({ commit }, payload) {
    return makeModelYearService.getYears(commit, payload);
  },

  getMakes({ commit }, payload) {
    return makeModelYearService.getMakes(commit, payload);
  },

  getMakesModels({ commit }, payload) {
    return makeModelYearService.getMakesModels(commit, payload);
  },

  getModels({ commit }, payload) {
    return makeModelYearService.getModels(commit, payload);
  },
};

const mutations = {
  setYears(state, response) {
    state.yearList = response.data;
  },

  setMakes(state, response) {
    state.makeList = response.data;
  },

  setMakesModels(state, response) {
    state.makeModelList = response.data;
  },

  setModels(state, response) {
    state.modelList = response.data;
  },
};

const getters = {
  getYears(state) {
    return state.yearList;
  },

  getMakes(state) {
    return state.makeList;
  },

  getMakesModels(state) {
    return state.makeModelList;
  },

  getModels(state) {
    return state.modelList;
  },
};

export const makeModelYear = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
