import http from "@/services/http.service";
import {
  CAR_YEARS,
  CAR_MAKES,
  CAR_MODELS,
  CAR_MAKES_MODELS,
} from "@/constants/apiUrls";

const getYears = async (commit, query) => {
  const response = await http.get(CAR_YEARS, { params: query });
  commit("setYears", response.data);
};

const getMakes = async (commit, query) => {
  const response = await http.get(CAR_MAKES, { params: query });
  commit("setMakes", response.data);
};

const getMakesModels = async (commit, query) => {
  const response = await http.get(CAR_MAKES_MODELS, { params: query });
  commit("setMakesModels", response.data);
};

const getModels = async (commit, query) => {
  const response = await http.get(CAR_MODELS, { params: query });
  commit("setModels", response.data);
};

export default {
  getYears,
  getMakes,
  getModels,
  getMakesModels,
};
