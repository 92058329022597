export const AUTH_TOKEN = "api/auth/login";
export const AUTH_TOKEN_REFRESH = "api/auth/refresh";
export const LOGOUT = "api/auth/logout";
export const PROFILE = "api/auth/user";
export const UPDATE_PROFILE = "api/auth/profile";
export const UPDATE_SALES_TAX_RATE = "api/auth/user/sales-tax-rate";
export const GET_SALES_TAX_RATE = "api/auth/user/sales-tax-rate";
export const POST = "api/posts";
export const INVENTORY_POSTS = "api/posts/inventory-posts";
export const VIN_SEARCH_REPORT = "api/vin-search";
export const MESSAGES = "api/messages";
export const UNREAD_MESSAGES_COUNT = "api/unread-messages-count";
export const MEMBERS = "api/members";
export const SHARE_MEMBER = "api/share-member";
export const POST_THREADS = "api/post-threads";
export const LEADS = "api/leads";
export const CAR_HISTORY = "api/vehicle-history";
export const CAR_HISTORY_REPORT = "api/vehicle-history-report";
export const SEND_PDF_TO_MAIL = "api/send-pdf-to-mail";
export const GET_MANUFACTURER = "api/interchange/manufacturer";
export const GET_YEAR = "api/interchange/year";
export const GET_CARLINE = "api/interchange/carline";
export const GET_P_TYPE = "api/interchange/pType";
export const GET_INVENTORY_SEARCH_P_TYPE = "api/search-inventory/pType";
export const GET_INDEX_LIST = "api/interchange/indexList";
export const GET_APPLICATIONS = "api/interchange/applications";
export const GET_INDEX_LIST_APP = "api/interchange/indexListApp";
export const SETUP_INTENT = "api/create-setup-intent";
export const DEFAULT_PAYMENT_METHOD = "api/default-payment-method";
export const ADD_DEFAULT_PAYMENT_METHOD = "api/add-default-payment-method";
export const NEW_DEFAULT_PAYMENT_METHOD = "api/new-default-payment-method";
export const ADD_DUE_PAYMENT = "api/add-due-payment";
export const GET_PAYMENT_RECEIPT_URL = "api/payment-receipt-url";
export const GET_PAYMENT_STATUS = "api/auth/check-payment-status";
export const SERVICES = "api/services";
export const BOOKLOG = "api/book-logs";
export const IMAGE = "api/medias";
export const IMAGE_UPLOAD = "redline/upload-image";
export const DOC_UPLOAD = "redline/upload-document";
export const MAKEOPTION = "api/makes";
export const MODELOPTION = "api/models";
export const YEAROPTION = "api/years";
export const TRIMOPTION = "api/trims";
export const INVOICES = "api/auth/payment-histories";
export const VIN_SEARCH = "api/book-logs/vin-search";
export const REPLY_MESSAGE = "api/posts/{post_id}/reply";
export const PREVIOUS_CAR_HISTORY = "api/vehicle-history";
export const PREVIOUS_VIN_SEARCH = "api/vin-checker-logs";
export const USERS = "api/users";
export const PART_NAME = "inventory/part-names";
export const INVENTORIES = "api/inventories";
export const ROOMS = "api/rooms";
export const SAVE_ROOMS = "api/rooms/save";
export const SHARE_INVENTORY = "api/share-inventory";
export const SHARE_OLD_INVENTORY = "api/share-old-inventory";
export const PART_CODE_NAME = "api/old-part-names";
export const OLD_INVENTORY_YEAR = "api/old-years";
export const OLD_INVENTORY_MODEL = "api/old-models";
export const OLD_INVENTORY_LIST = "api/old-inventory";
export const INVENTORY_TRIM_OPTION = "api/inventory-trims";
export const SAVE_DMV_PROCESSING = "api/dmv-processing";
export const UPDATE_DMV_PROCESSING = "api/dmv-processing/{dmv_processing}";
export const GET_CAR_PROFILES = "api/car-profiles";
export const GET_DMV_PROCESSING = "api/dmv-processing";
export const GET_MARKETING_TAGS = "api/marketing-campaign/tags";
export const GET_MARKETING_CONTACTS = "api/marketing-campaign/contacts";
export const SAVE_MARKETING_CONTACTS = "api/marketing-campaign/contacts";
export const UPDATE_MARKETING_CONTACTS = "api/marketing-campaign/contacts/{contact_id}";
export const DELETE_MARKETING_CONTACTS = "api/marketing-campaign/contacts/{contact_id}";
export const GET_MARKETING_COMPAIGN = "api/marketing-campaign/campaign";
export const SAVE_MARKETING_COMPAIGN = "api/marketing-campaign/campaign";
export const NEWS = "api/news";
export const GET_INVOICE = "api/invoice";
export const SAVE_INVOICE = "api/invoice/add";
export const UPDATE_INVOICE = "api/invoice/update";
export const GET_INVOICE_MANUFACTURER = "api/invoice/manufacturer";
export const GET_INVOICE_YEAR = "api/invoice/year";
export const GET_INVOICE_CARLINE = "api/invoice/carline";
export const GET_INVOICE_P_TYPE = "api/invoice/pType";
export const GET_INVOICE_PARTS = "api/invoice/parts";
export const GET_INVOICE_CUSTOMERS = "api/invoice/customers";
export const GET_INVOICE_STATISTICS = "api/invoice/dasboard-statistics";
export const GET_INVOICE_PDF = process.env.VUE_APP_API_HOST+"api/invoice/invoice-pdf";
export const GET_INVOICE_REPORT_PDF = process.env.VUE_APP_API_HOST+"api/invoice/invoice-report-pdf";
export const SEND_INVOICE_PDF = "api/invoice/send-pdf-to-mail";
export const GET_ASSOCIATE = "api/invoice/associate";
export const CORE_BUYING = "api/core-buying";
export const CORE_BUYING_SEARCH = "api/core-buying-search";
export const CORE_BUYING_USERS = "api/core-buying-users";
export const CORE_BUYING_PARTS = "api/core-buying-parts";

//hollander interchange
export const HOLLANDER_YEARS = "api/interchange/years-hollander";
export const HOLLANDER_MAKES = "api/interchange/makes-hollander";
export const HOLLANDER_MODELS = "api/interchange/models-hollander";

//inventory car make model year
export const CAR_YEARS = "api/car/years";
export const CAR_MAKES = "api/car/makes";
export const CAR_MODELS = "api/car/models";
export const CAR_MAKES_MODELS = "api/car/makes-models";

//vehicle databases report
export const VIN_HTML_REPORT = "api/vehicle-databases/vin-html-report";
export const PREMIUM_VIN_DECODER_REPORT = "api/vehicle-databases/premium-vin-decoder-report";