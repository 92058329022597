import http from "@/services/http.service";
import { POST, INVENTORY_POSTS, IMAGE_UPLOAD, DOC_UPLOAD } from "@/constants/apiUrls";
import tokenService from "./token.service";
import axios from "axios";

const posts = async (commit, query) => {
  const response = await http.get(POST, { params: query });
  commit("setPosts", response);
};

const getInventoryPosts = async (commit, query) => {
  const response = await http.get(INVENTORY_POSTS, { params: query });
  commit("setInventoryPosts", response);
};

const createPost = async (commit, payload) => {
  await http.post(POST, payload);
};

const uploadImage = async (commit, formData) => {
  const response = await axios.post(
    process.env.VUE_APP_MEDIA_UPLOAD_SERVER + IMAGE_UPLOAD,
    formData,
    {
      headers: {
        Authorization: `Bearer ${tokenService.getAccessToken()}`,
      },
    }
  );
  commit("setImageResponse", response);
};
const uploadFile = async (commit, formData) => {
  const response = await axios.post(
    process.env.VUE_APP_MEDIA_UPLOAD_SERVER + DOC_UPLOAD,
    formData,
    {
      headers: {
        Authorization: `Bearer ${tokenService.getAccessToken()}`,
      },
    }
  );
  commit("setFileResponse", response);
};

export default {
  posts,
  getInventoryPosts,
  createPost,
  uploadImage,
  uploadFile
};
